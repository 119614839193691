import { render, staticRenderFns } from "./OrderChangeTrain.vue?vue&type=template&id=555273d0&scoped=true&"
import script from "./OrderChangeTrain.vue?vue&type=script&lang=js&"
export * from "./OrderChangeTrain.vue?vue&type=script&lang=js&"
import style0 from "@/styles/orderCommon.less?vue&type=style&index=0&id=555273d0&lang=less&scoped=true&"
import style1 from "@/styles/orderHeadCommon.less?vue&type=style&index=1&id=555273d0&lang=less&scoped=true&"
import style2 from "./OrderChangeTrain.vue?vue&type=style&index=2&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.6@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "555273d0",
  null
  
)

export default component.exports